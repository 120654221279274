<template>
    <div class="side-bar">
        <ElMenu background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :router="true" :default-active="this.$route.path">
            <div v-for="menuItem in sideBarList" :key="menuItem.url">
                <ElSubmenuu v-if="menuItem.children && menuItem.children.length > 0" :index="item.url">
                    <template slot="title">
                        <i class="iconfont mr10" :class="menuItem.icon"></i>
                        <span>{{menuItem.text}}</span>
                    </template>
                    <div v-for="subItem in menuItem.children" :key="subItem.url">
                        <ElSubmenuu v-if="subItem.children && subItem.children.length > 0" :index="item.url">
                            <template slot="title">
                                <i class="iconfont mr10" :class="subItem.icon"></i>
                                <span>{{subItem.text}}</span>
                            </template>
                            
                        </ElSubmenuu>
                        <ElMenuItem :index="subItem.url">
                            <i class="iconfont mr10" :class="menuItem.icon"></i>
                            <span slot="title">{{subItem.text}}</span>
                        </ElMenuItem>
                    </div>
                </ElSubmenuu>
                <ElMenuItem :index="menuItem.url">
                    <i class="iconfont mr10" :class="menuItem.icon"></i>
                    <span slot="title">{{menuItem.text}}</span>
                </ElMenuItem>
            </div>
        </ElMenu>
    </div>
</template>

<script>
export default {
    computed: {
        sideBarList(){
            const sideBarList = this.$store.getters.sideBarList;
            
            return sideBarList.filter(item => this.permissions.includes(item.permissions));
            
        },
        permissions(){
            return this.$store.getters.permitList;
        }
    },
    data() {
        return {

        };
    },
    methods: {},
};
</script>

<style scoped lang='less'>
.side-bar{
    flex-shrink: 0;
    width: 240px;
    background-color: #313131;
}
</style>