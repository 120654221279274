<template>
    <div class="top-bar">
        <div class="title" @click="toHome">
            <img src="" alt="">
            <span>阅卷及一键归档系统</span>
        </div>
        <div class="user-menu">
            <el-dropdown>
                <span class="avator">
                    <img src="@/assets/imgs/user.png" alt="">
                    <span class="user-name">{{$store.getters.userInfo.realName}}</span>
                    <i class="el-icon-arrow-down el-icon--right" v-if="source !== 'CLIENT'"></i>
                </span>
                <el-dropdown-menu slot="dropdown" v-if="source !== 'CLIENT'">
                    <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { getCaseInfoKeyword } from '@/api/caseReading.js';
import { userLogout } from '@/api/loginApi.js';

export default {
    data(){
        return {
            searchList: [
                {
                    label: '立案号',
                    valule: 'case_name'
                }
            ],
            searchKey: 'caseName',
            searchValue: '',
            searchResult: [],
            visible: false
        }
    },
    computed: {
        source(){
            return this.$store.getters.source;
        }
    },
    watch: {
        $route: {
            handler(data){
                if(data.path === '/caseSearch' && data.query.searchValue){
                    this.searchValue = data.query.searchValue;
                }
            },
            immediate: true
        }
    },
    methods: {
        keydown(ev){
            if(ev.key === 'Enter'){
                this.search();
            }
        },
        search(){
            if(this.$route.query.searchKey === this.searchKey && this.$route.query.searchValue === this.searchValue){
                return;
            }
            this.$router[this.$route.path === '/caseSearch' ? 'replace' : 'push']({
                path: '/caseSearch',
                query: {
                    searchKey : this.searchKey,
                    searchValue: this.searchValue
                }
            })
            
        },
        async keywordChange(keyword,cb){
            getCaseInfoKeyword({ caseName: this.searchValue }).then(res => {
                cb(res);
            })
        },
        selectItem(data){
            if(this.$route.path === '/caseReading'){
                if(this.state.caseInfo.caseId === data.caseId){
                    return;
                }
                this.$router.replace(`/caseReading?caseId=${data.caseId}`);
            }else{
                let routeData = this.$router.resolve(`/caseReading?caseId=${data.caseId}`);
                window.open(routeData.href, '_blank');
                // this.$router.push(`/caseReading?caseId=${data.caseId}`);
            }
        },
        logout(){
            const loginType = this.$store.getters.configs['loginType'];
            if(loginType === 'CAS' || loginType === 'SSO'){
                window.location.href = 'api_v2/user/logout';
            }else{
                userLogout().then(res => {
                    this.$router.push('/login');
                })
                
            }
        },
        toHome(){
            if(this.source === 'CLIENT'){
                return;
            }
            if(this.$route.path === '/home'){
                return;
            }
            this.$router.push('/home');
        }
    }
}
</script>

<style scoped lang='less'>
.top-bar{
    background-color: #313131;
    height: 60px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
        color: #fff;
    .title{
        font-size: 20px;
        cursor: pointer;
    }
    .search{
        display: flex;
        align-items: center;
        /deep/ .el-select{
            width: 120px;
            .el-input{
                width: 100%;
            }
        }
        /deep/ .el-input{
            width: 500px;
        }
    }
    .user-menu{
        .avator{
            display: flex;
            align-items: center;
            color: #fff;
            cursor: pointer;
            img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .user-name{
                margin: 0 10px;
            }
        }
    }
}
</style>