<template>
    <div class="layout">
        <TopBar />
        <div class="layout-body">
            <SideBar />
            <div class="content">
                <!-- <div class="current-page">电子卷宗</div> -->
                <div class="main-page">
                    <router-view></router-view>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import TopBar from './TopBar';
import SideBar from './SideBar';

export default {
    components: {
        TopBar,SideBar
    },
    created(){
        document.title = '阅卷及一键归档系统';
    }
}
</script>

<style scoped lang='less'>
.layout-body{
    display: flex;
    .content{
        width: 0;
        flex-grow: 1;
        height: calc(100vh - 60px);
        background-color: #F7F7F7;
        color: #333;
        padding-left: 20px;
        /deep/ .current-page{
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            background-color: #F7F7F7;
        }
        .main-page{
            background-color: #fff;
            height: 100%;
            // height: calc(100% - 60px);
        }
    }
}
</style>